import React from 'react'
import "./Footer.css"
const Footer = () => {
  return (
    <div className='footer'>
        <div><p>Designed and developed by &copy; </p> <b>Muhammad Waleed Ahsan</b> </div>
      <div>
        <span>Terms and condition</span>
        <span>Privacy Policy</span>
        <span>Login</span>
      </div>
    </div>
  )
}

export default Footer
